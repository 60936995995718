/**
 * This class prepares all general animations
 * Note that then, it's called in setup-scripts.js file
 */
export default class Animations {
  /**
   * Animations constructor
   * @param {HTMLElement} content DON'T USE "document" TO GET HTML ELEMENTS, USE INSTEAD "content"
   */
  constructor(content) {
    this.fadeInUpElements = content.querySelectorAll(".js-fade-in-up > div");
    this.straggerItems = content.querySelectorAll(".js-element-fade-in-up");

    this.fadeInUp();
    this.fadeInUpStagger();
  }

  /**
   * This method creates a fadeInUp animation for each module
   * and is triggered when scrolling
   */
  fadeInUp() {
    const modules = gsap.utils.toArray(this.fadeInUpElements);
    modules.forEach((module) => {
      gsap.fromTo(
        module,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          scrollTrigger: {
            trigger: module,
            start: "top 90%",
            end: "top 30%",
          },
          onComplete: () => {
            // Reset css prop
            module.style.transform = "";
          },
        }
      );
    });
  }

  /**
   * Stragger animation
   */
  fadeInUpStagger() {
    gsap.utils.toArray(this.straggerItems).forEach((element, i) => {
      gsap.from(element, {
        y: 100,
        opacity: 0,
        scrollTrigger: {
          trigger: element,
          start: "top 90%",
        },
      });
    });
    ScrollTrigger.batch(this.straggerItems, {
      onEnter: (batch) =>
        gsap.to(batch, {
          autoAlpha: 1,
          opacity: 1,
          y: 0,
          stagger: 0.1,
          overwrite: true,
        }),
    });
  }
}
