/**
 * Class Footer JS
 *
 * @package DasHealth
 */
class Footer {
  /**
   * Constructor
   *
   */
  constructor() {
    this.footer = document.querySelector(".js-footer");
    this.footerSubMenus = this.footer.querySelectorAll(
      ".menu-item-has-children > a"
    );

    this.dropdownSubMenus();
  }

  /**
   * Method dropdown menus
   */
  dropdownSubMenus() {
    const self = this;
    this.footerSubMenus.forEach((item, key) => {
      item.addEventListener("click", (e) => {
        e.preventDefault();
        if (item.closest(".b-footer__menu--mobile")) {
          item.parentNode.classList.toggle("expanded");
          self.closeAllDropdownSubmenusFooter(key);
        }
      });
    });
  }

  /**
   * Method to close dropdown menus
   *
   * @param {number} exceptKey
   */
  closeAllDropdownSubmenusFooter(exceptKey) {
    this.footerSubMenus.forEach((item, key) => {
      if (exceptKey !== key) {
        item.parentNode.classList.remove("expanded");
      }
    });
  }
}

export default Footer;
