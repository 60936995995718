// To use another navbar uncomment the one you need, and comment the default one.
import Navbar from "./modules/navbar";
import Footer from "./modules/footer";
import Animations from "./app/useful/animations";
import Performance from "./app/useful/performance";

/**
 * Base scripts running all over the site
 */

new Navbar();
new Footer();
new Performance();
new Animations(document);