/**
 * This class adds control to the navbar
 */
export default class Navbar {
  /**
   * This is the navbar constructor
   */
  constructor() {
    this.navbar = document.querySelector(".js-navbar");
    this.hamburgerButton = this.navbar.querySelector(".js-hamburger-button");
    this.navigationBox = this.navbar.querySelector(".js-navbar-navigation");
    this.itemWithChildren = this.navbar.querySelectorAll(
      ".menu-item-has-children > .sub-menu-toggle"
    );
    this.subMenusArrows = this.navbar.querySelectorAll(".sub-menu-toggle");
    this.hero = document.querySelector(".m-main-hero");

    this.scrollEvent();
    this.hamburgerButtonEvent();
    this.dropdownSubMenus();
    this.backgroundMode();
  }

  /**
   * This method adds the css class scrolled when the window was scrolled
   */
  scrollEvent() {
    const self = this;
    window.addEventListener("scroll", function () {
      if (window.scrollY > 1) {
        self.navbar.classList.add("scrolled");
      } else {
        self.navbar.classList.remove("scrolled");
      }
    });
  }

  /**
   * This method adds the class expanded to the navbar when
   * the user click's the hamburger button
   */
  hamburgerButtonEvent() {
    const self = this;
    this.hamburgerButton.addEventListener("click", () => {
      self.hamburgerButton.classList.toggle("expanded");
      self.navigationBox.classList.toggle("expanded");
      self.navbar.classList.toggle("expanded");
    });
  }

  /**
   * Add dropdown behavior to elements that contains
   * sub elements
   */
  dropdownSubMenus() {
    const self = this;

    this.itemWithChildren.forEach((item, key) => {
      item.addEventListener("click", (e) => {
        self.closeAllDropdownsSubMenus(key);
        item.parentElement.classList.toggle("expanded");
      });
    });
  }

  /**
   * This method collapse all the dropdowns except the
   * one that was clicked.
   *
   * @param {int} exceptKey
   */
  closeAllDropdownsSubMenus(exceptKey) {
    this.subMenusArrows.forEach((arrow, key) => {
      if (exceptKey != key) {
        const menuItem = arrow.parentNode;
        menuItem.classList.remove("expanded");
      }
    });
  }

  /**
   * Check the background of the first module.
   */
  backgroundMode() {
    if (
      !this.hero ||
      this.hero.classList.contains("m-main-hero__bg-color--sand") ||
      this.hero.classList.contains("m-main-hero__bg-color--white")
    ) {
      this.navbar.classList.add("b-navbar--light");
    }
  }
}
